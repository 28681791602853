import cookies from 'js-cookie'

function inIFrame() {
	try {
		return window.self !== window.top
	} catch (e) {
		return true
	}
}

function addCss(href) {
	const link = document.createElement('link')
	link.href = href
	link.rel = 'stylesheet'

	document.head.appendChild(link)
}

function addScript(src) {
	const script = document.createElement('script')
	script.src = src

	document.body.appendChild(script)
}

if (process.env.NODE_ENV === 'production') {
	if (inIFrame()) {
		if (document.URL.includes('?close')) {
			cookies.set('closed', (new Date()).toISOString(), {
				sameSite: 'none',
				secure: true,
			})
			window.parent.postMessage('close', '*')
		} else {
			if (cookies.get('closed') === undefined) {
				window.parent.postMessage('open', '*')
			}
		}
	} else {
		const listener = window.addEventListener('message', (event) => {
			if (event.origin === 'https://popup.dishformyrv.com' && event.data === 'open') {
				const app = document.createElement('div')
				app.setAttribute('id', 'popupApp')
				document.body.appendChild(app)

				addCss('https://popup.dishformyrv.com/css/app.css')
				addScript('https://popup.dishformyrv.com/js/app.js')

				window.removeEventListener('message', listener)
			}
		})

		const iframe = document.createElement('iframe')
		iframe.src = 'https://popup.dishformyrv.com/loader.html'
		iframe.setAttribute('style', 'display:none!important;')

		document.body.appendChild(iframe)
	}
}
